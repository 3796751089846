import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element";

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//import Vue from 'vue'
import VueQuillEditor from "vue-quill-editor";

// 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//Vue.use(VueQuillEditor, /* { 默认全局 } */)

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

// 富文本图片大小
import imageResize from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件。
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

installElementPlus(app);

app.use(store).use(router).use(VueQuillEditor).mount("#app");
app.component("Vue3QuillEditor", QuillEditor);
Quill.register("modules/imageResize", imageResize);
Quill.register("modules/imageDrop", ImageDrop);

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? "生美后台管理系统" : to.meta.title;
  next();
});
