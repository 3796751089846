exports.PLATFROM_CONFIG = {
  // 开发环境：
  // baseURL: "https://localhost:44380"

  // 测试环境：
  baseURL: "https://skinapi-2024.skin79.com.cn",

  //  正式环境：
  //   baseURL: "https://api.skin79.com.cn/wechat",
};
